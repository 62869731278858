.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vh 1vh 2vh 1vh;
}

.app-name {
  font-size: 1.75vh;
  font-family: 'Special Elite', cursive;
}

.social-media-icons img {
  width: 3vh;
  height: 3vh;
}

.social-media-icons img:first-child {
  margin-left: 5px;
}

@media screen and (max-width: 1000px) {
  .app-name {
    font-size: 1.5vh;
  }
}

@media screen and (max-width: 1000px) and (orientation: landscape) {
  .app-name {
    font-size: 2vh;
  }
}