.token {
  width: 5vh;
  filter: invert(50%);
  z-index: 999;
}

.token:hover {
  cursor: pointer;
  filter: invert(100%);
}

.one {
  position: absolute;
  top: 5vh;
  right: 5vh;
}

.two {
  position: absolute;
  top: -4vh;
  right: 95vh;
}

.three {
  position: absolute;
  top: 45vh;
  left: 20vh;
}

.four {
  position: absolute;
  bottom: 1vh;
  right: 7vh;
}

.five {
  position: absolute;
  top: 30vh;
  right: 25vh;
}

/* @media screen and (max-width: 480px) {
  .one {
    top: 15vh;
    right: 20vh;
  }

  .two {
    top: 3vh;
    right: 22vh;
  }

  .three {
    top: 75vh;
    right: 5vh;
  }

  .four {
    bottom: 1vh;
    right: 5vh;
  }

  .five {
    top: 25vh;
    left: 5vh;
  }
}

@media screen and (min-width: 481px) and (max-width: 950px) {
  .token {
    width: 8vh;
  }

  .one {
    top: 15vh;
    right: 15vh;
  }

  .two {
    top: 3vh;
    right: 110vh;
  }

  .three {
    top: 15vh;
    left: 10vh;
  }

  .four {
    bottom: 0.5vh;
    right: 10vh;
  }

  .five {
    top: 25vh;
    right: 25vh;
  }
}

@media screen and (min-width: 950px) and (max-width: 1440px) {
  .one {
    top: 15vh;
    right: 10vh;
  }

  .two {
    top: 4vh;
    right: 95vh;
  }

  .three {
    top: 45vh;
    left: 20vh;
  }

  .four {
    bottom: 2.5vh;
    right: 10vh;
  }

  .five {
    top: 30vh;
    right: 25vh;
  }
} */

@media screen and (max-width: 1000px) {
  .one {
    top: 10vh;
    right: 8vh;
  }
  
  .two {
    top: 65vh;
    left: 5vh;
  }

  .three {
    top: 10vh;
    left: 2vh;
  }

  .four {
    bottom: 1.5vh;
    right: 8vh;
  }

  .five {
    top: 10vh;
    right: 20vh;
  }
}

@media screen and (max-width: 1000px) and (orientation: landscape) {
  .token {
    width: 10vh;
  }

  .one {
    top: 10vh;
    right: 8vh;
  }
  
  .two {
    top: 70vh;
    left: 5vh;
  }

  .three {
    top: 7vh;
    left: 10vh;
  }

  .four {
    bottom: 0vh;
    right: 8vh;
  }

  .five {
    top: 20vh;
    right: 100vh;
  }
}