@import url('https://fonts.googleapis.com/css2?family=Sirin+Stencil&family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body,
#root {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  overflow-x: hidden;
}

h4 {
  font-size: 1.75vh;
}

.app-container {
  position: relative;
  height: 90%;
  display: flex;
  flex-direction: column;
  margin: 8vh 8vh 0vh 8vh;
}

.outer-border {
  border: 3vh solid;
  border-image-source: url(./assets/flagborder.png);
  border-image-slice: 35%;
  border-image-repeat: repeat;
  flex: 1;
}

.inner-border {
  border: 3vh solid;
  border-image-source: url(./assets/flagborder.png);
  border-image-slice: 35%;
  border-image-repeat: repeat;
  height: 92%;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}


@media (max-width: 576px) {
  .app-container {
    margin: 4vh 0vh 0vh 0vh;
    height: 96%;
  }

  .image2 {
    width: 50vw;
    margin-top: -2vh;
  }

  .outer-border,
  .inner-border {
    border-width: 3vh;
    border-image-slice: 33%;
  }
  h3 {
    font-size: 1.5vh;
  }

}


@media (min-width: 576px) and (max-width: 992px) {
  .app-container {
    margin: 6vh 1vh 0vh 1vh;
    height: 94%;
  }

  .image2 {
    width: 50vw;
    margin-top: -5vh;
  }

  .outer-border,
  .inner-border {
    border-width: 3.5vh;
    border-image-slice: 32%;
    padding: 0.5vh;
  }
}


@media (min-width: 992px) and (max-width: 1440px) {
  .app-container {
    margin: 6vh;
  }

  .video-container {
    max-width: 540px;
  }

  .outer-border,
  .inner-border {
    border-width: 3vh;
    border-image-slice: 35%;
  }

  .image2 {
    width: 20vw;
  }
}
