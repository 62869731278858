.snake-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: 'ms_sans_serif', monospace;
}

.snake-container button {
  font-size: 1.75vh;
  cursor: pointer;
}

.snake-window {
  width: 100%;
  max-width: 40vh;
  z-index: 999;
  margin-top: 5vh;
}

.snake-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 4px solid green;
}

.snake-screen {
  max-width: 30vh;
  height: 100%;
  border: 4px solid rgb(62, 62, 255);
}

.snake-title-and-play {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30vh;
  height: 30vh;
  text-align: center;
  font-family: 'ms_sans_serif', monospace;
  background-color: black;
  color: #09ff00;
  row-gap: 1.5vh;
}

.snake-title {
  font-size: 2.5vh;
  font-family: 'ms_sans_serif', monospace;
}

.snake-play-button {
  margin-top: 1rem;
}

.snake-controls {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 4px solid rgb(142, 142, 142);
  width: 30vh;
  padding: 1vh 0vh;
  background-color: black;
}

.snake-buttons {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
  border: 2px solid white;
  border-radius: 25px;
  background-color: #000000;
  padding: 5px;
}

.snake-buttons button,
.snake-title-and-play button,
.snake-title-and-play a {
  padding: 0px 20px;
  background: #000;
  color: #00ff00;
  font-family: 'ms_sans_serif', sans-serif;
  border: 1px solid #00ff00;
  box-shadow: 1px 1px 0px #000;
  text-decoration: none;
}
.snake-buttons button:hover {
  background: #00ff00;
  color: #000;
}

.row-1,
.row-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.2vh;
}

.row-2 {
  display: flex;
  justify-content: space-between;
  column-gap: 7.5vh;
}

.snake-failure-container {
  display: none;
}

@media screen and (max-width: 1000px) {
  .snake-window {
    max-width: 35vh;
  }

  .snake-screen {
    width: 25vh;
  }

  .snake-title-and-play {
    width: 25vh;
    height: 25vh;
  }

  .snake-controls {
    width: 25vh;
    height: 20vh;
  }

  .snake-buttons {
    padding: 0vh 1vh;
  }

  .row-1,
  .row-3 {
    margin: 0.5vh;
  }

  .snake-buttons button {
    padding: 2.5vh 2vh 3vh 2vh;
  }

  .snake-window button {
    font-size: 2vh;
  }
}

@media screen and (max-width: 1000px) and (orientation: landscape) {

  .snake-container {
    display: none;
  }

  .snake-failure-container {
    display: block;
    margin-top: 5vh;
  }

  .snake-failure-window {
    height: 50vh;
    width: 80vh;
  }

  .snake-failure-content {
    display: flex;
    flex-direction: column;
    row-gap: 5vh;
    text-align: center;
  }
}