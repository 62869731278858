.scrolling-text-container {
  position: fixed;
  width: 101vw;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  column-gap: 5vh;
  border-top: 5px solid black;
  z-index: 9998;
  margin-top: -0.1vh;
  margin-left: -0.2vh;
}

.row-1 {
  top: 0vh;
  width: 200vw;
}

.row-2 {
  top: 12vh;

}

.row-3 {
  top: 24vh;
  width: 200vw;
}

.row-4 {
  top: 36vh;
}

.row-5 {
  top: 48vh;
}

.row-6 {
  top: 60vh;
}

.row-7 {
  top: 72vh;
}

.row-8 {
  top: 84vh;
}

.row-9 {
  top: 96vh;
}

.row-10 {
  display: none;
}

.row-11 {
  display: none;
}

.row-12 {
  display: none;
}

.scrolling-text-wrapper-left {
  display: flex;
  column-gap: 5vh;
  animation: scrollLeft 30s linear infinite;
}

.scrolling-text-wrapper-right {
  display: flex;
  column-gap: 5vh;
  animation: scrollRight 30s linear infinite;
}

.scrolling-text {
  font-size: 8vh;
  color: rgb(0, 0, 255);
  font-family: 'Bauhaus 93', sans-serif;
  font-style: italic;
  white-space: nowrap;
}

@keyframes scrollLeft {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes scrollRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@media screen and (max-width: 1000px) {
  .scrolling-text-container {
    margin-left: 0.1vh;
  }
  .scrolling-text {
    font-size: 6vh;
  }

  .row-2 {
    top: 9vh;
  }
  
  .row-3 {
    top: 18vh;
  }
  
  .row-4 {
    top: 27vh;
  }
  
  .row-5 {
    top: 36vh;
  }
  
  .row-6 {
    top: 45vh;
  }
  
  .row-7 {
    top: 54vh;
  }
  
  .row-8 {
    top: 63vh;
  }
  
  .row-9 {
    top: 72vh;
  }
  .row-10 {
    display: flex;
    top: 81vh;
  }
  .row-11 {
    display: flex;
    top: 90vh;
  }
  .row-12 {
    display: flex;
    top: 99vh;
  }
}

@media screen and (max-width: 1000px) and (orientation: landscape) {
  .row-1 {
    width: 200vw;
  }
  .row-3 {
    width: 200vw;
  }
  .scrolling-text-container {
    width: 200vw;
  }
}