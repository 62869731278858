@import url('https://fonts.googleapis.com/css2?family=Gloock&family=Sirin+Stencil&family=Special+Elite&family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@font-face {
  font-family: 'ms_sans_serif';
  src: url('react95/dist/fonts/ms_sans_serif.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Bauhaus 93';
  src: url('./fonts/BAUHS93.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  margin-block-start: 0em;
  margin-block-end: 0em;
}

h4, h5, h6 {
  font-family: "Urbanist", sans-serif;
}

html,
body {
  background-color: #faf9f6;
  animation: fadeInAnimation ease-in 0.35s;
}
