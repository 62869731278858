

/* ----------------------------------------------
 * Generated by Animista on 2023-6-20 21:20:22
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-down-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}
@keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}

.video-container {
  max-width: 640px;
  width: 100%;
  height: auto;
  z-index: 999;
}

.video-player {
  width: 100%;
  height: 100%;
}

.button-container {
  max-width: 30vh;
  width: 100%;
  height: auto;
}

.rookie-button {
  background-color: white;
  border: 5px outset rgb(166, 166, 166);
  border-radius: 10px;
  text-align: center;
  text-decoration: none;
  padding: 3vh 2vh;
  font-size: 3vh;
  color: black;
  font-family: 'Special Elite', cursive;
}

.rookie-button:hover {
  cursor: pointer;
  background-color: blue;
  color: white;
}

.rookie-button:active {
  border-style: inset;
  background-color: black;
  color: white;
}

.rookie-button-image {
  width: 100%;
  height: 100%;
}

.rookie-button-image:active {
	-webkit-animation: scale-down-center 0.05s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-down-center 0.05s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* Small devices (e.g., smartphones)
@media (max-width: 576px) {
  .button-container {
    max-width: 25vh;
  }
  .video-container {
    max-width: 250px;
  }
}

/* Medium devices (e.g., tablets) */
/* @media (min-width: 576px) and (max-width: 992px) {
  .video-container {
    max-width: 420px;
  }
} */

/* Large devices (e.g., desktops) */
/* @media (min-width: 992px) and (max-width: 1440px) {
  .video-container {
    max-width: 540px;
  }
} */

@media screen and (max-width: 1000px) {
  .rookie-button {
    font-size: 2.5vh;
  }
}

@media screen and (max-width: 1000px) and (orientation: landscape) {
  .video-container {
    margin-top: 5vh;
    max-width: 80vh;
  }
}