.blur {
  filter: blur(5px);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s,
    visibility 0.3s;
  font-family: 'Special Elite', cursive;
}

.modal.show {
  opacity: 1;
  visibility: visible;
}

.modal-content-container {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 1vh;
}

.modal-post-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2vh;
  padding: 0vh 0vh;
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 3vh;
  margin-bottom: 5vh;
}

.modal-header-content {
  text-transform: lowercase;
}

.modal-comment-content {
  text-transform: lowercase;
}

.blue-button {
  padding: 10px 20px 5px 20px;
  font-size: 3vh;
  cursor: pointer;
  background-color: #0022ff;
  border: 3px outset black;
  color: white;
  font-family: 'Special Elite', cursive;
  text-decoration: none;
}

.blue-button:hover {
  cursor: pointer;
  background-color: blue;
  color: white;
}

.blue-button:active {
  background-color: black;
  color: white;
}

.modal h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.modal button {
  padding: 10px 20px 5px 20px;
  font-size: 3vh;
  cursor: pointer;
  background-color: #ff1e00;
  border: 3px outset black;
  color: white;
  font-family: 'Special Elite', cursive;
}

.modal button:hover {
  color: black;
}

.modal button:active {
  background-color: black;
  color: white;
}

.modal-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-footer-text {
  text-align: center;
  font-family: 'Special Elite', cursive;
}

@media screen and (max-width: 1000px) {
  .modal-content-container {
    justify-content: center;
    align-items: center;
  }

  .rookie-modal-logo {
    margin-bottom: 3vh;
    width: 40vh;
  }

  .modal-content {
    height: 25vh;
  }

  .modal h1 {
    font-size: 2.5vh;
  }

  .modal p {
    font-size: 1.5vh;
  }
}

@media screen and (max-width: 1000px) and (orientation: landscape) {
  .modal-content-container {
    justify-content: center;
    align-items: center;
    row-gap: 3vh;
  }

  .rookie-modal-logo {
    width: 50vw;
  }

  .modal-content {
    height: 50vh;
  }

  .modal h1 {
    font-size: 4vh;
  }

  .modal p {
    font-size: 2.5vh;
  }

  .modal-footer-text {
    font-size: 3vh;
  }
}
